import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router";
import moment from "moment";
import classes from "../DoctorProfile/DoctorProfile.module.css";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { Slot } from "../BookSchedule/Slot.component";
import { Alert } from "react-bootstrap";
import {
  getWalkInId,
  groupSlotsByTime,
  checkOfffline,
  getMessage,
} from "./Helper";
import unAuthorizedError from "../Errors/unAuthorizedError";
import { PROCEDURE_SLOTS } from "../DoctorComponents/MyScheduleFees/Constants";
import Grow from "@material-ui/core/Grow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ShowMoreButton } from "../BookSchedule/ShowMoreButton";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import {
  CLINIC_GET_APPT_SLOTS,
  NEXT_SLOTS,
  PUBLIC_GET_APPT_SLOTS,
  PUBLIC_GET_DOCTOR_SLUG,
} from "./SlotsAPIHandler/APIConstants";
import { MONTH_NAMES } from "./constant";
import { C2CConsole } from "../../Util/Helper/C2CConsole";
import "./slots.scss";
import { c2cPostApiHandler } from "../../Util/c2cApiHandler";

const Slots = ({
  selectedDate,
  doctorId,
  onSelect,
  setStartTime,
  consultType, //= 0
  setConsultData = () => {},
  followUpInfo = {},
  selectedProcedure,
  doctor,
  slugUrl,
  reschedule,
  apiConsultationType,
  getSlotTime,
  handleSlotToEMpty,
  handleInstruction,
  doctorSlug,
  procedure_id,
  type,
  selectedClinic,
  setDateSelectEnable,
  clinicID, // passing for patient side rescheduling
  handleDate,
  selectedResourceProcedure,
  isResource,
  handleSelectResourceSlot,
  hasResourceAccess,
  addBooking,
  procedures,
  booking_type,
  isReschedule,
  isConsultant,
  isSchedule,
  setDuration,
}) => {
  const currentRef = useRef({});
  const history = useHistory();

  const clinicId = localStorage.getItem(LOCALSTORAGEKEY.CLINIC_ID);
  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  const userType = localStorage.getItem(LOCALSTORAGEKEY.USER_TYPE);
  let isPatient = userType === "patient";
  let id = localStorage.getItem("procedure_id");

  const [morningSlots, setMorningSlots] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [afternoonSlots, setAfternoonSlots] = useState([]);
  const [eveningSlots, setEveningSlots] = useState([]);
  const [midnightSlots, setMidnightSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedSlotObj, setSelectedSlotObj] = useState({});
  const [totalSlots, setTotalSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [slotsNotAvailable, setSlotsNotAvailable] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("No slots available.");
  const [procedureDetails, setprocedureDetails] = useState("");
  const routeParams = useParams();
  const [consultationDetails, setConsultationDetails] = useState("");
  const [doctorDetails, setDoctorDetails] = useState("");
  const [nextAvailableDate, setNextAvailableDate] = useState(null);
  const [midnightRowsToDisplay, setMidnightRowsToDisplay] = useState(
    type === "modal" ? 16 : 20
  );
  const [morningRowsToDisplay, setMorningRowsToDisplay] = useState(
    type === "modal" ? 16 : 20
  );
  const [afternoonRowsToDisplay, setAfternoonRowsToDisplay] = useState(
    type === "modal" ? 16 : 20
  );
  const [eveningRowsToDisplay, setEveningRowsToDisplay] = useState(
    type === "modal" ? 16 : 20
  );
  const [showMidnightSlot, setShowMidnightSlot] = useState(false);
  const [showEveningSlot, setShowEveningSlot] = useState(false);
  const [showMorningSlot, setShowMorningSlot] = useState(false);
  const [showAfternoonSlot, setShowAfternoonSlot] = useState(false);
  const [showNextSlotButton, setShowNextSlotButton] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [slotStyle, setSlotstyle] = useState("");
  const [alternateNo, setAlternateNo] = useState("");

  // Lifecycle Methods ===>

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (
        window.innerWidth != windowSize.width &&
        window.innerHeight != windowSize.height
      ) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize]);

  useEffect(() => {
    setSlotstyle(type === "modal" ? classes.modalWrap : classes.slotWrap);
    showMoreOption();
  }, [windowSize]);

  useEffect(() => {
    getDoctorSlugApi();
  }, [selectedProcedure, selectedClinic]);

  useEffect(() => {
    if (doctorSlug !== undefined && !hasResourceAccess && !addBooking) {
      if (procedures?.length > 0 || isSchedule) {
        fetchApi();
      } else {
        setShowNextSlotButton(false);
        setDisplayMessage("offline_payment");
        setSlotsNotAvailable(true);
      }
    }
  }, [consultationDetails, procedureDetails, procedures]);

  useEffect(() => {
    if (!hasResourceAccess && !addBooking) {
      if (
        procedures?.length > 0 ||
        isReschedule ||
        isConsultant ||
        isSchedule
      ) {
        fetchApi();
      } else {
        setShowNextSlotButton(false);
        setDisplayMessage("offline_payment");
        setSlotsNotAvailable(true);
      }
    } else if (hasResourceAccess || addBooking) {
      if (selectedResourceProcedure?.common_procedure) {
        if (selectedResourceProcedure?.callAPI) {
          fetchApi();
        }
      } else {
        fetchApi();
      }
    }
  }, [
    selectedDate,
    doctorId,
    selectedProcedure,
    selectedClinic,
    selectedResourceProcedure,
    procedures,
  ]); //newly added consultationDetails,procedureDetails

  useEffect(() => {
    setMorningSlots([]);
    setAfternoonSlots([]);
    setEveningSlots([]);
    setMidnightSlots([]);
    if (procedures && procedures?.length == 0) {
      setSlotsNotAvailable(true);
    } else {
      setSlotsNotAvailable(false);
    }
    setSelectedSlot("");
    setSelectedSlotObj({});
  }, [selectedDate]);

  useEffect(() => {
    //? Slot Selected
    const selectedSlotObject = totalSlots.find((slot) =>
      selectedSlotObj.is_walkin
        ? slot.walkin_id === selectedSlotObj.walkin_id
        : !slot.is_walkin && slot.id === selectedSlot
    );
    onSelect(selectedSlot, selectedSlotObject, selectedSlotObj);
    if (setStartTime) {
      setStartTime(selectedSlotObject?.start_time);
    }
  }, [selectedSlot, selectedSlotObj, onSelect, totalSlots]);

  useEffect(() => {
    if (
      apiConsultationType &&
      selectedDate &&
      doctorId &&
      !hasResourceAccess &&
      !addBooking
    ) {
      handleSlotToEMpty();
      getAppointmentsSlots();
    } else if (hasResourceAccess || addBooking) {
      if (selectedResourceProcedure?.common_procedure) {
        if (selectedResourceProcedure?.callAPI) {
          handleSlotToEMpty();
          getAppointmentsSlots();
        }
      } else {
        handleSlotToEMpty();
        getAppointmentsSlots();
      }
    }
  }, [selectedDate, doctorId, selectedResourceProcedure]); //, apiConsultationType

  // <===

  // API Calls ===>

  const getDoctorSlugApi = () => {
    let slug = false;
    let meeting_id = localStorage.getItem("meeting_id"); // to check if its an instant consultation
    if (doctor && doctor.doctor_slug) slug = doctor.doctor_slug;
    if (slugUrl) slug = slugUrl;
    if (doctorSlug) {
      slug = doctorSlug;
    }

    let payload = {
      doctor_url_short_code: slug ? slug : routeParams.slug,
      ...(!meeting_id && {
        clinic_id: clinicID ? clinicID : clinicId,
      }),
    };
    let resData = c2cPostApiHandler(PUBLIC_GET_DOCTOR_SLUG, payload);

    resData.then(([res, err]) => {
      if (!err) {
        setLoading(false);
        if (res.data.statusCode == 200) {
          setDoctorDetails(res.data.doctor);
          setAlternateNo(
            res.data?.alternate_numbers?.length > 0
              ? res.data?.alternate_numbers[0].phone_no
              : ""
          );
          setprocedureDetails(res.data.procedure_list);
          localStorage.setItem(
            LOCALSTORAGEKEY.IS_ASSOCIATED,
            res.data.is_associated_to_clinic
          );
          setConsultationDetails([...res.data.regular_followup_list]);
          if (handleInstruction) {
            handleData(res.data);
          } //setting instruction
        }
      }
    });
  };

  const nextAvailableSlots = (followUpConsultationType) => {
    let payload = {
      user_type: userType,
    };
    if (hasResourceAccess || addBooking) {
      payload = {
        ...payload,
        doctor_id: selectedResourceProcedure?.doctor_id,
        procedure_id: selectedResourceProcedure?.id,
        consultation_type: selectedResourceProcedure?.consultation_type ?? "4",
      };
    } else {
      payload = {
        ...payload,
        doctor_id: doctorId,
        consultation_type: followUpConsultationType
          ? followUpConsultationType
          : apiConsultationType?.id ?? consultType,
        ...(id && {
          procedure_id: id,
          consultation_type: localStorage.getItem(
            "procedure_consultation_type"
          ),
        }),
        ...(procedure_id && {
          procedure_id: procedure_id,
          consultation_type: "4",
        }),
      };
      if (apiConsultationType?.id ?? consultType === "4") {
        payload["procedure_id"] = apiConsultationType.value;
      }
    }

    if (clinicID) {
      payload["clinic_id"] = clinicID;
    } else {
      if (clinicId !== "" && clinicId !== undefined && clinicId !== null) {
        payload["clinic_id"] = clinicId;
      }
    }

    setLoading(true); // Set loading state initially
    c2cPostApiHandler(NEXT_SLOTS, payload)
      .then(([res, error]) => {
        setLoading(false); // Set loading state to false after receiving the response
        if (res?.data?.statusCode === 200) {
          const nextSlotDate = res.data.date;
          const parts = nextSlotDate.split("-");
          const day = parseInt(parts[2]);
          const month = MONTH_NAMES[parseInt(parts[1]) - 1];
          const year = parseInt(parts[0]);
          const formattedDate = `${day} ${month} ${year}`;
          setShowNextSlotButton(true);
          setSlotsNotAvailable(false);
          setNextAvailableDate(formattedDate);
          setDisplayMessage(null);
          const nextDate = moment(nextSlotDate);
          const afterAnYear = moment().add(1, "years");
          const isMoreThanOneYear = nextDate.isAfter(afterAnYear);
          if (isMoreThanOneYear) {
            setShowNextSlotButton(false);
            setSlotsNotAvailable(true);
            setDisplayMessage("No slots found.");
          }
        } else if (res?.data?.statusCode === 500) {
          setShowNextSlotButton(false);
          setSlotsNotAvailable(true);
          if (booking_type == "doctor") {
            setDisplayMessage("No slots found.");
          } else {
            setDisplayMessage("offline_payment");
          }
        }
      })
      .catch((error) => {
        setLoading(false); // Set loading state to false in case of an error
        C2CConsole.error(error);
      });
  };

  const getAppointmentsSlots = () => {
    if (selectedDate) {
      setLoading(true);
      let payload = {
        user_type: userType,
        doctor_id: doctorId,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        walkin_slot_required: true,
      };
      if (hasResourceAccess || addBooking) {
        setSelectedSlot("");
        setSelectedSlotObj({});
        payload = {
          ...payload,
          doctor_id: selectedResourceProcedure?.doctor_id,
          consultation_type: selectedResourceProcedure?.consultation_type ?? 4,
          procedure_id: selectedResourceProcedure?.id,
        };
      } else {
        payload = {
          ...payload,
          consultation_type: apiConsultationType.id,
        };
        if (apiConsultationType.id == "4") {
          payload["procedure_id"] = apiConsultationType.value;
        }
      }

      if (clinicId !== "" && clinicId !== undefined && clinicId !== null) {
        payload["clinic_id"] = clinicId;
      }
      setDateSelectEnable(false);

      let resData = c2cPostApiHandler(
        clinicId !== "" && clinicId !== undefined && clinicId !== null
          ? CLINIC_GET_APPT_SLOTS
          : PUBLIC_GET_APPT_SLOTS,
        payload
      );
      resData.then(([res, err]) => {
        if (!err) {
          setDateSelectEnable(true);
          if (res?.data?.statusCode == 200) {
            res?.data?.duration
              ? setDuration(res?.data?.duration)
              : setDuration(2);
            setLoading(false);
            setSlotsNotAvailable(false);
            let slots = getWalkInId(res.data.time_slots);
            setTotalSlots(slots);
            setShowNextSlotButton(false);
            return saveSlots(slots, 0);
          } else if (
            res?.data?.statusCode == 505 ||
            res?.data?.statusCode == 506 ||
            res?.data?.statusCode == 507
          ) {
            setLoading(false);
            showNextAvailableSlot();
            setDuration(2);
          } else {
            saveSlots([], 0);
            setLoading(false);
            showNextAvailableSlot();
            setDuration(2);
          }
        }
      });
    }
  };

  const fetchApi = () => {
    setLoading(true);
    setSlotsNotAvailable(false);
    showMoreOption();
    //? Fetch API
    setMorningSlots([]);
    setAfternoonSlots([]);
    setEveningSlots([]);
    setMidnightSlots([]);
    if (selectedDate && doctorId && !apiConsultationType) {
      if (
        (consultType == 0 || consultType == 5 || consultType == 6) &&
        localStorage.getItem("Tele_Consult_Follow_Up") !== "Follow-Up"
      ) {
        let term = {
          doctor_id: doctorId,
          user_type: userType,
          date: moment(selectedDate).format("YYYY-MM-DD"),
          consultation_type: consultType,
        };
        if (hasResourceAccess || addBooking) {
          setSelectedSlot("");
          setSelectedSlotObj({});
          term = {
            ...term,
            doctor_id: selectedResourceProcedure?.doctor_id,
            ...(selectedResourceProcedure?.id && {
              procedure_id: selectedResourceProcedure?.id,
              consultation_type:
                selectedResourceProcedure?.consultation_type ?? "4",
            }), //sending procedure id and consultatuon type while reschedule
          };
        } else {
          term = {
            ...term,
            ...(id && {
              procedure_id: id,
              consultation_type: localStorage.getItem(
                "procedure_consultation_type"
              ),
            }),
            ...(procedure_id && {
              procedure_id: procedure_id,
              consultation_type: "4",
            }), //sending procedure id and consultatuon type while reschedule
          };
        }
        if (clinicID) {
          term["clinic_id"] = clinicID;
        } else {
          if (clinicId) {
            term["clinic_id"] = clinicId;
          }
        }
        //if from patient side rescheduling, use clinic id from props else from localstorage

        currentRef.current = term;
        let url = isPatient
          ? "/public/get-booking-slots-by-doctor-id-rev1"
          : clinicId !== "" && clinicId !== undefined && clinicId !== null
          ? "/clinic/get-appointments-slots-by-doctor-id"
          : "public/get-appointments-slots-by-doctor-id";

        afterLoginApiAxios.post(url, term).then((response) => {
          setLoading(false);

          if (currentRef.current === term) {
            if (response.data?.statusCode == 200) {
              let current_consultaion_type = isPatient
                ? response.data.data.consultation_type
                : response.data.consultation_type;
              localStorage.setItem(
                "consultation_type",
                current_consultaion_type
              );
              localStorage.setItem(
                "consultation-type",
                current_consultaion_type
              );
              if (response.data?.vacation_data?.start_date !== undefined) {
                setStartDate(response.data.vacation_data.start_date);
                setEndDate(response.data.vacation_data.end_date);
                showNextAvailableSlot();
              } else {
                let slots_data = [];
                if (isPatient) {
                  slots_data = response.data.data.time_slots;
                } else {
                  slots_data = response.data.time_slots;
                }

                const consultationSettings = {
                  fees: response.data?.fees,

                  duration: PROCEDURE_SLOTS.find(
                    (slot) => slot.slotType === +response.data?.slot_type
                  ),
                };
                setConsultData(consultationSettings);
                setTotalSlots(slots_data);
                setShowNextSlotButton(false);
                let isOffline = false;
                if (id || procedure_id) {
                  //newly added || procedure_id
                  isOffline = checkOfffline(
                    selectedProcedure,
                    procedureDetails,
                    doctor,
                    doctorDetails,
                    setDisplayMessage,
                    setSlotsNotAvailable,
                    procedure_id,
                    followUpInfo
                  );
                } else if (consultType == 5 || consultType == 6) {
                  isOffline = checkConsultationOfffline(consultationDetails);
                }
                if (isOffline == false) {
                  return saveSlots(slots_data, response.data?.data?.counter);
                } else if (!clinicId) {
                  return saveSlots(slots_data, response.data?.data?.counter);
                }
              }
            } else if (
              response.data?.statusCode == 502 ||
              response.data?.statusCode == 505 ||
              response.data?.statusCode == 507
            ) {
              showNextAvailableSlot();
            } else if (response.data?.statusCode == 510) {
              setShowNextSlotButton(false);
              setSlotsNotAvailable(true);
              setDisplayMessage("offline_payment"); // when online payment is disable then we are calling this notification for patient end
            } else {
              if (!isPatient && userType) {
                showNextAvailableSlot();
                saveSlots([], 0);
                setLoading(false);
              }
            }
          }
        });
      } else if (consultType == 1) {
        //? Follow Up Time Slots
        let params = {};
        if (localStorage.getItem("followup_consultation_type")) {
          params["consultation_type"] = localStorage.getItem(
            "followup_consultation_type"
          );
        }
        afterLoginApiAxios
          .post(
            "patient/patient-follow-up-slots-by-doctor-id",
            {
              patient_id: followUpInfo.patient_id || userId,
              patient_name:
                followUpInfo.patient_name ||
                localStorage.getItem("patient_name"),
              patient_dob:
                followUpInfo.patient_dob || localStorage.getItem("patient_dob"),
              patient_account_id:
                followUpInfo.patient_account_id ||
                localStorage.getItem("patient_account_id"),
              consultation_type:
                followUpInfo.consultation_type_id ||
                localStorage.getItem("consultation-type"),
              ...params,
              doctor_id:
                followUpInfo.doctor_id ||
                JSON.parse(localStorage.getItem("doctor")).doctor_id,
              date: moment(selectedDate).format("YYYY-M-D"),
              ...((clinicId || followUpInfo?.clinic_id) && {
                clinic_id: clinicId || followUpInfo?.clinic_id,
              }),
            },
            {
              headers: {
                authorization: localStorage.getItem("id_token"),
              },
            }
          )
          .then((response) => {
            setLoading(false);
            let actualFollowUpConsultationType = localStorage.getItem(
              "followup_consultation_type"
            );
            if (response.data?.statusCode === 200) {
              setShowNextSlotButton(false);
              // added condition to check whether the doctor has taken vaccation for follow up consultation
              if (response.data?.vacation_data?.start_date !== undefined) {
                setStartDate(response.data.vacation_data.start_date);
                setEndDate(response.data.vacation_data.end_date);
                showNextAvailableSlot(actualFollowUpConsultationType);
              } else {
                const slots = response.data.data.time_slots;

                const consultData = {
                  fees: response.data.fees,
                  duration: PROCEDURE_SLOTS.find(
                    (slot) => slot.slotType === +response.data.slot_type
                  ), //.label
                  valid: response.data.follow_up_end_date,
                };
                setConsultData(consultData);
                setTotalSlots(slots);

                return saveSlots(
                  slots,
                  response.data?.data?.counter,
                  actualFollowUpConsultationType
                );
              }
            } else if (
              response.data?.statusCode === 511 ||
              response.data?.statusCode === 513
            ) {
              showNextAvailableSlot(actualFollowUpConsultationType);
            }
          })
          .catch((err) => {
            if (err.response?.status === 403) {
              unAuthorizedError(() => {
                history.push("/patient/login");
              });
            }
          });
      } else if (
        localStorage.getItem("Tele_Consult_Follow_Up") === "Follow-Up"
      ) {
        setLoading(false);
      }
    }
  };

  // <===

  // Helper Methods ===>

  const handleData = (data) => {
    handleInstruction(data);
  };

  const checkConsultationOfffline = (consultationData) => {
    if (selectedProcedure && selectedProcedure.value) {
      if (userType != "doctor" && userType != "staff") {
        if (consultationData && consultationData.length) {
          let selectedOne = consultationData.find(
            (data) => data.consultation_type == selectedProcedure.id
          );
          if (
            selectedOne &&
            (selectedOne.online_payment_enabled == "False" ||
              selectedOne.online_payment_enabled == false) // selectedOne.consultation_method === "False" &&
          ) {
            setDisplayMessage("offline_payment");
            setSlotsNotAvailable(true);
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    } else {
      if (userType != "doctor" && userType != "staff") {
        if (consultationData && consultationData.length) {
          let selectedOne = consultationData.find(
            (data) => data.consultation_type == consultType
          );
          if (
            (followUpInfo?.rescheduling_available &&
              (selectedOne.online_payment_enabled == "False" ||
                selectedOne.online_payment_enabled == false)) ||
            (selectedOne &&
              (selectedOne.online_payment_enabled == "False" ||
                selectedOne.online_payment_enabled == false)) // selectedOne.consultation_method === "False" &&
          ) {
            setDisplayMessage("offline_payment");
            setSlotsNotAvailable(true);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const handleNextSlotClick = () => {
    const formattedDate = moment(nextAvailableDate).format("YYYY-MM-DD");
    handleDate(new Date(formattedDate));
  };

  const showMoreOption = () => {
    setShowEveningSlot(true);
    setShowMorningSlot(true);
    setShowAfternoonSlot(true);
    setShowMidnightSlot(true);
    if (
      (windowSize.width == 320 && windowSize.height == 568) ||
      (windowSize.width == 320 && windowSize.height == 480)
    ) {
      setRows(5);
    } else if (windowSize.width == 768 && windowSize.height == 1024) {
      setRows(12);
    } else if (windowSize.width < 640) {
      setRows(10);
    } else if (windowSize.width >= 640 && windowSize.width < 770) {
      setRows(12);
    } else if (windowSize.width >= 770 && windowSize.width <= 1024) {
      setRows(12);
    } else if (windowSize.width > 1024 && windowSize.width < 1200) {
      setRows(12);
      //setRows(type === "modal" ? 12 : 12);
    } else if (windowSize.width >= 1200 && windowSize.width < 1300) {
      setRows(type === "modal" ? 12 : 16);
    } else if (windowSize.width >= 1300) {
      setRows(type === "modal" ? 16 : 20);
    } else {
      setRows(type === "modal" ? 16 : 24);
    }
  };

  const setRows = (rows) => {
    setEveningRowsToDisplay(rows);
    setAfternoonRowsToDisplay(rows);
    setMorningRowsToDisplay(rows);
    setMidnightRowsToDisplay(rows);
  };

  // creating an unique id for walk-in slots since the id is same for walk-in and normal slot

  // Splits slots in Morning, Afternoon, Evening slots

  const saveSlots = (fetchedSlots, counter, actualFollowUpConsultationType) => {
    const {
      tempMorningSlots,
      tempAfternoonSlots,
      tempEveningSlots,
      tempMidnightSlots,
    } = groupSlotsByTime(fetchedSlots, selectedDate, counter);

    if (
      tempMorningSlots.length === 0 &&
      tempAfternoonSlots.length === 0 &&
      tempEveningSlots.length === 0 &&
      tempMidnightSlots.length === 0
    ) {
      showNextAvailableSlot(actualFollowUpConsultationType);
    } else {
      setDisplayMessage(false);
      setSlotsNotAvailable(false);
    }

    setMorningSlots(tempMorningSlots);
    setAfternoonSlots(tempAfternoonSlots);
    setEveningSlots(tempEveningSlots);
    setMidnightSlots(tempMidnightSlots);
  };

  const showMore = (type) => {
    if (type === "midnightSlots") {
      setMidnightRowsToDisplay(midnightSlots.length);
      setShowMidnightSlot(false);
    } else if (type === "eveningSlots") {
      setEveningRowsToDisplay(eveningSlots.length);
      setShowEveningSlot(false);
    } else if (type === "afternoonSlots") {
      setAfternoonRowsToDisplay(afternoonSlots.length);
      setShowAfternoonSlot(false);
    } else {
      setMorningRowsToDisplay(morningSlots.length);
      setShowMorningSlot(false);
    }
  };

  const showNextAvailableSlot = (followUpConsultationType) => {
    if (!addBooking) {
      nextAvailableSlots(followUpConsultationType);
    } else {
      setDisplayMessage("No slots found.");
      setSlotsNotAvailable(true);
    }
  };

  // <===

  // Display UI ===>

  const displayAlertUI = () => {
    return (
      <div className="text-center no-slot-found-alert-wrapper">
        <Alert variant={"danger"} style={{ opacity: 1 }}>
          {" "}
          {/**,marginTop:"5rem" */}
          {displayMessage === "vac-error" && (
            <span>
              Doctor is not available for consultation during the period:{" "}
              <strong>
                {moment(startDate).format("DD MMM, YYYY")} to{" "}
                {moment(endDate).format("DD MMM, YYYY")}.
              </strong>{" "}
              Please select any other day.
            </span>
          )}
          {displayMessage === "offline_payment" && (
            <p>{getMessage(alternateNo, clinicId)}</p>
          )}
          {displayMessage === "No slots found." && displayMessage}
        </Alert>
      </div>
    );
  };

  const creatNextSlots = () => {
    return (
      <div className="next-avaliablity" onClick={() => handleNextSlotClick()}>
        Next Available Slot On {nextAvailableDate}
      </div>
    );
  };

  const createLoadingUI = () => {
    return (
      <div className="row align-items-center justify-content-center ">
        <CircularProgress className="mb-3" />
      </div>
    );
  };

  const displaySlots = (
    slotList,
    title,
    imgPath,
    rowsToDisplay,
    showSlot,
    slotName
  ) => {
    return (
      <Grow in={slotList.length > 0} timeout={500}>
        <div>
          {slotList?.length ? (
            <div className={classes.slots}>
              <div className="morning-top">
                <img src={process.env.PUBLIC_URL + imgPath} alt="" /> {title}
              </div>
              <div className={"a " + classes.slotTimes + " " + slotStyle}>
                {slotList.slice(0, rowsToDisplay).map((slot, index) => (
                  <Slot
                    key={index}
                    slot={slot}
                    startTime={slot.start_time}
                    endTime={slot.end_time}
                    selected={
                      selectedSlotObj.is_walkin
                        ? slot.walkin_id === selectedSlotObj.walkin_id
                        : !slot.is_walkin && slot.id === selectedSlot
                    }
                    id={slot.walkin_id ? slot.walkin_id : slot.id}
                    onClick={() => {
                      if (getSlotTime) {
                        getSlotTime(slot.start_time, slot);
                      }
                      setSelectedSlot(slot.id);
                      setSelectedSlotObj(slot);
                      if (
                        (hasResourceAccess || addBooking) &&
                        !(selectedSlotObj.is_walkin
                          ? slot.walkin_id === selectedSlotObj.walkin_id
                          : !slot.is_walkin && slot.id === selectedSlot)
                      ) {
                        handleSelectResourceSlot(slot.id, slot, selectedDate);
                      }
                    }}
                    type={type}
                    reschedule={reschedule}
                  />
                ))}
              </div>
              {showSlot && slotList?.length > rowsToDisplay && (
                <ShowMoreButton
                  isShow={showSlot}
                  onClick={() => showMore(slotName)}
                />
              )}
            </div>
          ) : null}
        </div>
      </Grow>
    );
  };

  // <===

  return (
    <div
      className={`${
        hasResourceAccess
          ? "resource-slot-wrapper slots-container "
          : "clinic-slot-wrapper slots-container"
      }`}
    >
      {loading && createLoadingUI()}
      {showNextSlotButton && creatNextSlots()}
      {slotsNotAvailable ? (
        displayAlertUI()
      ) : (
        <>
          {displaySlots(
            midnightSlots,
            "midnight: 12:00 AM - 06:00 Am",
            "/images/morning-icon-new.svg",
            midnightRowsToDisplay,
            showMidnightSlot,
            "midnightSlots"
          )}
          {displaySlots(
            morningSlots,
            "morning: 06:00 AM - 12:00 Pm",
            "/images/morning-icon-new.svg",
            morningRowsToDisplay,
            showMorningSlot,
            "morningSlots"
          )}
          {displaySlots(
            afternoonSlots,
            "Afternoon: 12:00 pM - 06:00 Pm",
            "/images/afternoon-icon.png",
            afternoonRowsToDisplay,
            showAfternoonSlot,
            "afternoonSlots"
          )}
          {displaySlots(
            eveningSlots,
            "Evening: 06:00 pM - 12:00 Am",
            "/images/evening-icon-new.svg",
            eveningRowsToDisplay,
            showEveningSlot,
            "eveningSlots"
          )}
        </>
      )}
    </div>
  );
};

export default Slots;
