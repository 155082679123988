import React, { createRef, useEffect, useMemo, useState } from "react";
import classes from "./MultipleImageSelector.module.css";
import { Alert, Button } from "react-bootstrap";
import PreviewImages from "./PreviewImages";
import PreviewNames from "./PreviewNames";
import swal from "sweetalert";
import { v4 as uuid } from "uuid";
import { C2CConsole } from "../../../Util/Helper/C2CConsole";

const MultipleFileSelector = ({
  selectedImages,
  onlyImages,
  annotation,
  maxFileSize,
  showWarning,
  chooseFileText,
  hideAfterUpload, // to hide the list of file after uploaded
  ...props
}) => {
  maxFileSize = maxFileSize || 20;
  let input = createRef();
  const [overAllSize, setOverAllSize] = useState(0);
  const [filesState, setFiles] = useState([]);
  useEffect(() => {
    if (filesState.length === 0) {
      localStorage.removeItem("currentFileSize");
    }
    selectedImages(filesState);
  }, [filesState]);
  useEffect(() => {
    localStorage.removeItem("currentFileSize");
    return () => {
      localStorage.removeItem("currentFileSize");
    };
  }, []);
  useEffect(() => {
    if (hideAfterUpload) {
      setFiles([]);
    }
  }, [hideAfterUpload]);

  const chooseImageClickHandler = () => {
    input.click();
  };
  let multipleFiles = true;
  multipleFiles = props.single ? false : multipleFiles;

  const calcTotalSize = (files) => {
    let totalSize = 0;
    for (const file of files) {
      const fileSize = file.size / 1000 / 1000; //File Size in mb

      if (fileSize > maxFileSize) {
        //File Size is greater than size limit
        swal({
          title: "File Size Exceeds Limit!",
          text: `File cannot be greater than ${maxFileSize} MB`,
          icon: "error",
        });
        return 0;
      }
      totalSize += fileSize;
    }
    return totalSize;
  };

  const imageChangeHandler = (event) => {
    const files = event.target.files;
    let filesArray = [];
    let currentFileSize = calcTotalSize(files).toFixed(2);
    let presentFileSize = localStorage.getItem("currentFileSize");
    let midState = Number(presentFileSize) + Number(currentFileSize);
    if (presentFileSize) {
      if (props.maxTotalCap < midState) {
        localStorage.setItem("currentFileSize", Number(presentFileSize));
        return swal({
          title: "Overall Files Size Exceeds Limit!",
          text: `Total Files Size cannot be greater than ${props.maxTotalCap} MB`,
          icon: "error",
        });
      } else {
        localStorage.setItem(
          "currentFileSize",
          Number(presentFileSize) + Number(currentFileSize)
        );
      }
    } else {
      localStorage.setItem("currentFileSize", currentFileSize);
    }

    const totalFilesSize = calcTotalSize(files);
    if (!totalFilesSize) return;

    if (overAllSize + totalFilesSize > maxFileSize) {
      //Overall File Size is greater than OverAll File Size limit
      if (props.maxtotalCap) {
        localStorage.setItem("currentFileSize", presentFileSize);
      }
      return swal({
        title: "Overall File Size Exceeds Limit!",
        text: `Total Files cannot be greater than ${maxFileSize} MB`,
        icon: "error",
      });
    }

    let totalFileSize = localStorage.getItem("currentFileSize");
    if (
      props.maxTotalCap &&
      totalFileSize &&
      totalFileSize > props.maxTotalCap
    ) {
      return swal({
        title: "Overall Files Size Exceeds Limit!",
        text: `Total Files Size cannot be greater than ${props.maxTotalCap} MB`,
        icon: "error",
      });
    }
    // setOverAllSize((totalSize) => totalSize + totalFilesSize);

    // Looping over all the Images
    for (const file of files) {
      const fileSize = file.size / 1024 / 1024; //File Size in mb
      if (onlyImages) {
        // If onlyImages prop is true then, Image will only be allowed
        if (!file.type.includes("image")) {
          C2CConsole.error("Image is not Selected");
          return;
        }
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      const lastDot = file.name.lastIndexOf(".");
      reader.onloadend = function () {
        const fileObject = {
          value: reader.result,
          name: file.name,
          size: (file.size / 1024 / 1024).toFixed(2),
          type: file.name.substring(lastDot + 1),
          fileName: file.name.substring(0, lastDot),
          fileType: file.type,
          id: uuid(),
          fileDate: file,
        };
        var filePath = fileObject.name;
        var allowedExtensions =
          /(\.jpg|\.jpeg|\.png|\.bmp|\.svg|\.doc|\.docx|\.pdf|\.txt|\.gif|\.xls|\.csv)$/i;
        if (!allowedExtensions.exec(filePath)) {
          alert(
            "The allowed extension is .jpeg/.jpg/.png/.gif/.svg/.bmp/.pdf/.doc/.xls/.csv/.docx only."
          );
        } else {
          let temp = [];
          filesArray = [...filesArray, fileObject];
          if (multipleFiles) {
            temp = [...filesArray, ...filesState];
          } else {
            temp = [fileObject];
          }
          setFiles(temp);
        }
      };
    }
  };

  const removeClickHandler = (id) => {
    // setOverAllSize(overAllSize - filesState[id].size);
    if (props.maxTotalCap) {
      let allFilesSize = localStorage.getItem("currentFileSize");
      localStorage.setItem(
        "currentFileSize",
        Number(allFilesSize) - filesState[id]?.size
      );
    }
    const copyState = filesState;
    copyState.splice(id, 1);
    setFiles([...copyState]);
  };

  // If onlyImages prop is true then, Image will pe previewed
  const renderImages = useMemo(() => {
    if (filesState.length)
      return (
        <div className={classes.previewedImagesRoot}>
          {filesState.map((image, index) => (
            <PreviewImages
              image={image}
              key={index}
              id={index}
              removeClickHandler={removeClickHandler}
            />
          ))}
        </div>
      );
  }, [filesState.length]);

  // If onlyImages prop is false then, Only Names will pe previewed
  const renderName = useMemo(() => {
    if (filesState.length)
      return (
        <div className={classes.previewedNamesRoot} style={{ ...props.styles }}>
          {filesState.map((image, index) => (
            <PreviewNames
              name={image.name}
              key={index}
              id={index}
              removeClickHandler={removeClickHandler}
            />
          ))}
        </div>
      );
  }, [filesState]);
  const acceptedFileType = { allow: "image/*,.doc, .docx,.pdf,.txt,.xls,.csv" };
  return (
    <div {...props}>
      <div className={classes.multiFileSelectorDiv}>
        <input
          multiple={multipleFiles}
          type={"file"}
          ref={(ref) => (input = ref)}
          className={classes.input}
          onChange={imageChangeHandler}
          onClick={(e) => {
            e.target.value = "";
          }}
          accept={
            props.acceptedFileType
              ? props.acceptedFileType.allow
              : acceptedFileType.allow
          }
        />
        <Button
          className="choose-file-btn"
          variant={"light"}
          disabled={props.disabled}
          onClick={chooseImageClickHandler}
          style={{
            background: " #17BAE0 0% 0% no-repeat padding-box",
            borderRadius: "6px",
            color: "#FFFFFF",
            width: "100%",
          }}
        >
          {chooseFileText ? chooseFileText : annotation || " Choose Files"}
        </Button>
        {onlyImages ? renderImages : renderName}
        {showWarning && (
          <div className="danger-text">Max File Size: {maxFileSize} MB</div>
        )}
      </div>
    </div>
  );
};

export default MultipleFileSelector;
